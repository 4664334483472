import PortalApi from '../helpers/api/portalApi';
import { ModelCustomer, ModelCustomerItrack } from '../models/ApiCustomer.type';

export default class ApiCustomer extends PortalApi<ModelCustomer> {
    route = 'stock/customer';

    getCustomersCodeBook = async (): Promise<ModelCustomerItrack[]> => {
        return this.getCodeBook('/codebook');
    };
}
