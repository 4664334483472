import React, { useState } from 'react';
import * as view from './CustomerDropDown.view';
import * as type from './CustomerDropDown.type';
import Wrapper from '../../helpers/wrapper';
import { IStockLocation, LocationWithPagnator } from '../../models/ApiStockLocation.type';
import ApiStockLocation from '../../api/ApiStockLocation';
import { LoadMore } from './CustomerDropDown.type';
import { OrderAlphabetically } from '../../helpers/converter';
// import module

const apiStockLocation = new ApiStockLocation();

const CustomerDropDown: React.FC<type.CustomerDropDownProps> = (props) => {
    const [dataCustomers, setDataCustomers] = useState<IStockLocation[]>([]);

    const loadMoreCustomer = async (search, _, { page }): Promise<LoadMore> => {
        const { items }: LocationWithPagnator = await apiStockLocation.getWithPagination(
            page,
            10,
            '',
            '',
            search,
            [],
            props.extendUrl ?? ''
        );

        let newItemsList: IStockLocation[] = items;
        if (newItemsList && !!newItemsList.length) {
            newItemsList = OrderAlphabetically<IStockLocation>(items, 'name');
            if (props.hideOption) {
                newItemsList = newItemsList.filter((locationFrom) => locationFrom.id !== props.hideOption);
            }
            setDataCustomers(newItemsList);
        }

        return {
            options: newItemsList,
            hasMore: newItemsList.length >= 1,
            additional: { page: page + 1 }
        };
    };

    return (
        <view.CustomerDropDownContent
            data-testid='CustomerDropDown-testid'
            loadMoreCustomer={loadMoreCustomer}
            {...props}
            customerList={
                props.hideOption
                    ? dataCustomers.filter((locationFrom) => locationFrom.id !== props.hideOption)
                    : dataCustomers
            }
        />
    );
};

export default Wrapper(CustomerDropDown);
