import React from 'react';
import * as view from './UiPhoneInputNumber.view';
import * as type from './UiPhoneInputNumber.type';
import { parsePhoneNumber } from 'react-phone-number-input';
import Wrapper from '../../../helpers/wrapper';

const UiPhoneInputNumber: React.FC<type.UiPhoneInputNumberProps> = (props): JSX.Element => {
    const extraValidation = (value): boolean => {
        const parsePhone = parsePhoneNumber(value);
        if (parsePhone && parsePhone.country === 'SK') {
            if (value.length < 13) {
                return false;
            }
        }
        return true;
    };

    return (
        <view.UiPhoneInputNumberContent
            data-testid={'UiPhoneInputNumber-testid'}
            {...props}
            extraValidation={extraValidation}
        />
    );
};

export default Wrapper(UiPhoneInputNumber);
