import React, { useEffect, useRef, useState } from 'react';
import * as view from './Reports.view';
import * as type from './Reports.type';
import Wrapper from '../../helpers/wrapper';
import ReportingApi from '../../api/ApiReporting';
import { createEmbeddingContext } from 'amazon-quicksight-embedding-sdk';
// import module

const Reporting = new ReportingApi();

const Reports: React.FC<type.ReportsProps> = (): JSX.Element => {
    const [selectedReport, setSelectedReport] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const qsElement = useRef(null) as React.MutableRefObject<HTMLDivElement | null>;

    const getEmebededUrl = async (selectedReport?: string): Promise<void> => {
        if (selectedReport) {
            setIsLoading(true);
            Reporting.generateEmbeddedUrl({ dashboardId: selectedReport }).then((data) => {
                if (data) {
                    loadQuicksight(data);
                    setIsLoading(false);
                }
            });
        }
    };

    useEffect(() => {
        getEmebededUrl(selectedReport);
    }, [selectedReport]);

    const loadQuicksight = async (data) => {
        qsElement.current?.removeChild(qsElement.current?.children[0]);

        const options = {
            url: data.embeddedUrl,
            container: '#quicksight-content',
            scrolling: 'no',
            height: '100%',
            width: '100%',
            footerPaddingEnabled: true
        };

        const embeddingContext = await createEmbeddingContext();
        await embeddingContext.embedDashboard(options);
    };

    return (
        <view.ReportsContent
            data-testid={'Reports-testid'}
            selectedReport={selectedReport}
            setSelectedReport={setSelectedReport}
            isLoading={isLoading}
            qsElement={qsElement}
        />
    );
};

export default Wrapper(Reports);
