/* eslint-disable max-len */
import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, IconButton, InputAdornment } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { Form, Formik, FormikProps } from 'formik';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import * as style from './EditShippingOrder.style';
import * as type from './EditShippingOrder.type';
import {
    AutocompleteElement,
    CheckboxElement,
    ChipsAutocompleteElement,
    InputElement,
    LoadingElement,
    OutlinedInputElement
} from '../../FormElements';
import * as ShippingOrderState from '../../../states/component/ShippingOrder';
import Translator from '../../../states/global/Translator';
import { IUAlert } from '../../../styled/global.style';
import UiTable from '../../../components/Ui/UiTable/UiTable';
import { Close } from '@mui/icons-material';
import CustomerDropDown from '../../CustomerDropDown/CustomerDropDown';
import { Loading } from '../../../helpers/Loading';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { User } from '../../../states/global/User';
import { FullDataUser } from '../../User/User.type';
import ShipperDropDown from '../../ShipperDropDown/ShipperDropDown';
import StockLocationDropdown from '../../StockLocationDropdown/StockLocationDropdown';
import { LocationsItems } from '../../../models/ApiItems.type';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { getFullScreenModal } from '../../../helpers/getFullScreenModal';
// import module

export const EditShippingOrderContent: React.FC<type.EditShippingOrderViewProps> = (props): JSX.Element => {
    const setResetFilters = useSetRecoilState<boolean>(ShippingOrderState.StatusFiltersTable);
    const Trans = useRecoilValue(Translator);
    const userState = useRecoilValue<FullDataUser | null>(User);
    const heightScreen = window.screen.availHeight >= 750 ? 310 : 210;

    return (
        <style.EditShippingOrderContent data-testid='EditShippingOrderContent'>
            <Dialog
                fullScreen={getFullScreenModal()}
                fullWidth={true}
                maxWidth='xl'
                open={props.open}
                onClose={props.closeModal}
                aria-labelledby='responsive-dialog-title'
                className='drawer_full_page'
            >
                <style.AppBarContainer style={{ position: 'relative', textAlign: 'center' }}>
                    <style.UiToolbar>
                        <style.TitlePage>
                            {props.resendOrder
                                ? `${Trans('messages.t.resend_order')} - #${props.shippingOrderId}`
                                : `${Trans('messages.t.edit_shipping_order')} - #${props.shippingOrderId}`}
                        </style.TitlePage>

                        <style.ContainerBtnBoxItems>
                            <IconButton edge='end' color='inherit' onClick={props.closeModal} aria-label='close'>
                                <Close />
                            </IconButton>
                        </style.ContainerBtnBoxItems>
                    </style.UiToolbar>
                </style.AppBarContainer>

                {props.loading ? (
                    <Loading />
                ) : (
                    <Formik
                        initialValues={props.valuesInitForm}
                        onSubmit={(values: type.IEditOrderForm, actions) =>
                            props.handleSubmit(values, actions.resetForm, actions.setSubmitting)
                        }
                        validationSchema={props.formschema}
                    >
                        {(propsF: FormikProps<type.IEditOrderForm>) => {
                            const {
                                values,
                                touched,
                                errors,
                                handleBlur,
                                handleChange,
                                isSubmitting,
                                setFieldValue,
                                submitForm
                            } = propsF;
                            return (
                                <Form style={{ display: 'inline-block' }}>
                                    <style.UiScrollbarsEditOrder
                                        autoHeight
                                        autoHide
                                        renderThumbVertical={({ style, ...props }) => (
                                            <div {...props} className='scrollYcustom' style={{ ...style }} />
                                        )}
                                    >
                                        <DialogContent>
                                            <Grid container>
                                                <>
                                                    <Grid item lg={3} md={3} sm={12} xs={12}>
                                                        <style.FieldsetLeftContainer>
                                                            <legend className='modalLegend'>
                                                                {Trans(
                                                                    props.resendOrder
                                                                        ? 'messages.t.shipping_order'
                                                                        : 'messages.t.edit_shipping_order'
                                                                )}
                                                            </legend>

                                                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                                                <StockLocationDropdown
                                                                    name='stockLocationFrom'
                                                                    label={Trans('messages.t.from')}
                                                                    value={values.stockLocationFrom}
                                                                    error={!!errors.stockLocationFrom}
                                                                    onChange={(value: LocationsItems) => {
                                                                        props.resetListItems(
                                                                            value
                                                                                ? { id: value.id, name: value.name }
                                                                                : '',
                                                                            setFieldValue
                                                                        );
                                                                        setResetFilters(true);
                                                                        props.checkLocationDuplication(
                                                                            value ? value.id?.toString() : '',
                                                                            values.stockLocationTo,
                                                                            setFieldValue
                                                                        );
                                                                    }}
                                                                    required={true}
                                                                    textRequired={Trans(
                                                                        'messages.p.this_field_is_required'
                                                                    )}
                                                                    lg={12}
                                                                    md={12}
                                                                    sm={12}
                                                                />

                                                                {props.resetLocationTo ? (
                                                                    <LoadingElement
                                                                        label={Trans('messages.t.loading')}
                                                                        lg={12}
                                                                        md={12}
                                                                        sm={12}
                                                                    />
                                                                ) : (
                                                                    <CustomerDropDown
                                                                        name='stockLocationTo'
                                                                        label={Trans('messages.t.to')}
                                                                        value={values.stockLocationTo}
                                                                        error={!!errors.stockLocationTo}
                                                                        onChange={(value) => {
                                                                            // eslint-disable-next-line max-len
                                                                            setFieldValue(
                                                                                'shipmentAcceptanceEmail',
                                                                                value ? value.email : ''
                                                                            );

                                                                            setFieldValue(
                                                                                'stockLocationTo',
                                                                                value
                                                                                    ? { id: value.id, name: value.name }
                                                                                    : ''
                                                                            );
                                                                            props.checkLocationDuplication(
                                                                                value ? value?.id?.toString() : '',
                                                                                values.stockLocationFrom,
                                                                                setFieldValue
                                                                            );
                                                                        }}
                                                                        required={true}
                                                                        lg={12}
                                                                        md={12}
                                                                        sm={12}
                                                                    />
                                                                )}

                                                                <CustomerDropDown
                                                                    name='customerLocation'
                                                                    extendUrl='/all?onlyByCustomer=true&order=name:asc'
                                                                    label={Trans('messages.t.order_for_customer')}
                                                                    value={values.customerLocation}
                                                                    error={!!errors.customerLocation}
                                                                    onChange={(value) => {
                                                                        setFieldValue(
                                                                            'customerLocation',
                                                                            value ? value : ''
                                                                        );
                                                                    }}
                                                                    required={false}
                                                                    lg={12}
                                                                    md={12}
                                                                    sm={12}
                                                                />

                                                                <InputElement
                                                                    label={Trans('messages.t.invoice_number')}
                                                                    name='saleOrderId'
                                                                    value={values.saleOrderId}
                                                                    error={errors.saleOrderId}
                                                                    touched={touched.saleOrderId}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    lg={12}
                                                                    md={12}
                                                                    sm={12}
                                                                    required={true}
                                                                />

                                                                <InputElement
                                                                    label={Trans('messages.t.invoice_incoterms')}
                                                                    name='invoiceIncoerms'
                                                                    value={values.invoiceIncoerms}
                                                                    error={errors.invoiceIncoerms}
                                                                    touched={touched.invoiceIncoerms}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    lg={12}
                                                                    md={12}
                                                                    sm={12}
                                                                />

                                                                <InputElement
                                                                    label={Trans('messages.t.invoice_exw')}
                                                                    name='invoiceExw'
                                                                    value={values.invoiceExw}
                                                                    error={errors.invoiceExw}
                                                                    touched={touched.invoiceExw}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    lg={12}
                                                                    md={12}
                                                                    sm={12}
                                                                />

                                                                <ChipsAutocompleteElement
                                                                    label={Trans(
                                                                        'messages.t.shipment_acceptance_email'
                                                                    )}
                                                                    name='shipmentAcceptanceEmail'
                                                                    value={(values.shipmentAcceptanceEmail || '')
                                                                        .split(';')
                                                                        .filter((email) => email !== '')}
                                                                    error={errors.shipmentAcceptanceEmail}
                                                                    touched={touched.shipmentAcceptanceEmail}
                                                                    onChange={(e, value: any) => {
                                                                        values.shipmentAcceptanceEmail =
                                                                            value.join(';');
                                                                        setFieldValue(
                                                                            'shipmentAcceptanceEmail',
                                                                            value.join(';')
                                                                        );
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    lg={12}
                                                                    md={12}
                                                                    sm={12}
                                                                    required={false}
                                                                />

                                                                {!props.resendOrder && (
                                                                    <>
                                                                        <Grid
                                                                            item
                                                                            lg={12}
                                                                            md={12}
                                                                            sm={12}
                                                                            xs={12}
                                                                            className='textFieldFormik'
                                                                        >
                                                                            <LocalizationProvider
                                                                                dateAdapter={AdapterMoment}
                                                                            >
                                                                                <MobileDateTimePicker
                                                                                    label={Trans(
                                                                                        'messages.t.shipped_at'
                                                                                    )}
                                                                                    disableFuture={true}
                                                                                    value={values.shippedAt}
                                                                                    onChange={(newValue) => {
                                                                                        setFieldValue(
                                                                                            'shippedAt',
                                                                                            newValue
                                                                                                ? props.fromUserTimezoneToTimezone(
                                                                                                      newValue
                                                                                                  )
                                                                                                : ''
                                                                                        );
                                                                                    }}
                                                                                    mask={
                                                                                        props.getDateMask ??
                                                                                        '____-__-__ __:__'
                                                                                    }
                                                                                    inputFormat={
                                                                                        userState
                                                                                            ? `${userState?.userSetting.dateFormat} ${userState?.userSetting.timeFormat}`
                                                                                            : 'YYYY-MM-DD HH:mm'
                                                                                    }
                                                                                    renderInput={(params) => (
                                                                                        <TextField
                                                                                            {...params}
                                                                                            name='shippedAt'
                                                                                            size='small'
                                                                                            helperText={
                                                                                                params.error
                                                                                                    ? errors.shippedAt
                                                                                                        ? errors.shippedAt
                                                                                                        : errors.shippedAt
                                                                                                    : ''
                                                                                            }
                                                                                            error={!!errors.shippedAt}
                                                                                        />
                                                                                    )}
                                                                                />
                                                                            </LocalizationProvider>
                                                                        </Grid>

                                                                        <InputElement
                                                                            label={Trans('messages.t.tracking_number')}
                                                                            name='trackingId'
                                                                            value={values.trackingId}
                                                                            error={errors.trackingId}
                                                                            touched={touched.trackingId}
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            lg={12}
                                                                            md={12}
                                                                            sm={12}
                                                                        />

                                                                        <ShipperDropDown
                                                                            name='shipperSelect'
                                                                            paramsUrl={[]}
                                                                            label={Trans('messages.t.shipper')}
                                                                            value={values.shipperSelect}
                                                                            error={!!errors.shipperSelect}
                                                                            onChange={(value) => {
                                                                                setFieldValue(
                                                                                    'shipperSelect',
                                                                                    value ? value : ''
                                                                                );
                                                                            }}
                                                                            required={false}
                                                                            textRequired={Trans(
                                                                                'messages.p.this_field_is_required'
                                                                            )}
                                                                            lg={12}
                                                                            md={12}
                                                                            sm={12}
                                                                        />
                                                                    </>
                                                                )}

                                                                <InputElement
                                                                    label={Trans('messages.t.sale_order_id')}
                                                                    name='originalImportedTrentInvoiceNumber'
                                                                    value={props.shippingOrder?.originalImportedTrentInvoiceNumber || '---'}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    disabled={true}
                                                                    lg={12}
                                                                    md={12}
                                                                    sm={12}
                                                                />

                                                                <CheckboxElement
                                                                    label={Trans('messages.t.tracking_enabled')}
                                                                    name='trackingEnabled'
                                                                    value={values.trackingEnabled}
                                                                    onChange={handleChange}
                                                                    lg={4}
                                                                    md={4}
                                                                    sm={6}
                                                                />

                                                                {Object.keys(errors).length > 0 ? (
                                                                    <Grid
                                                                        item
                                                                        lg={12}
                                                                        md={12}
                                                                        sm={12}
                                                                        xs={12}
                                                                        className='textFieldFormik'
                                                                    >
                                                                        <IUAlert variant='outlined' severity='warning'>
                                                                            {Trans(
                                                                                'messages.p.all_fields_marked_are_required'
                                                                            )}
                                                                        </IUAlert>
                                                                    </Grid>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </Grid>
                                                        </style.FieldsetLeftContainer>
                                                    </Grid>

                                                    <Grid item lg={9} md={9} sm={12} xs={12}>
                                                        <style.FieldsetContainer>
                                                            <legend className='modalLegend'>
                                                                {Trans('messages.t.choose_items')}
                                                            </legend>
                                                            <Grid container justifyContent='flex-start' direction='row'>
                                                                <AutocompleteElement
                                                                    label={Trans('messages.t.type')}
                                                                    name='type'
                                                                    value={values.type}
                                                                    error={errors.type}
                                                                    touched={touched.type}
                                                                    size='small'
                                                                    onBlur={handleBlur}
                                                                    setFieldValue={setFieldValue}
                                                                    options={[
                                                                        {
                                                                            value: 'all',
                                                                            label: Trans('messages.t.all')
                                                                        },
                                                                        {
                                                                            value: 'hub',
                                                                            label: Trans('messages.t.hub')
                                                                        },
                                                                        {
                                                                            value: 'tablet',
                                                                            label: Trans('messages.t.tablet')
                                                                        },
                                                                        {
                                                                            value: 'sensor',
                                                                            label: Trans('messages.t.sensor')
                                                                        },
                                                                        {
                                                                            value: 'generic_item',
                                                                            label: Trans('messages.t.generic_item')
                                                                        },
                                                                        {
                                                                            value: 'box',
                                                                            label: Trans('messages.t.box')
                                                                        }
                                                                    ]}
                                                                    defaultValue={{
                                                                        value: values.type,
                                                                        label: values.type
                                                                    }}
                                                                    onChange={(e, value: any) => {
                                                                        setFieldValue('type', value ? value.value : '');
                                                                        setFieldValue('sku', '');
                                                                        props.setLocationFilter({
                                                                            ...props.extraFiltering,
                                                                            type: value ? value.value : '',
                                                                            serialNumber: ''
                                                                        });
                                                                        setResetFilters(true);
                                                                        props.setSerialNumber('');
                                                                    }}
                                                                    lg={3}
                                                                    md={3}
                                                                    sm={12}
                                                                    xs={12}
                                                                />
                                                                {props.resetFilter ? (
                                                                    <LoadingElement
                                                                        label={Trans('messages.t.loading')}
                                                                        lg={3}
                                                                        md={3}
                                                                        sm={12}
                                                                    />
                                                                ) : (
                                                                    <>
                                                                        {values.type === 'all' || values.type === '' ? (
                                                                            ''
                                                                        ) : (
                                                                            <AutocompleteElement
                                                                                label={Trans('messages.t.sku')}
                                                                                name='sku'
                                                                                value={values.sku}
                                                                                error={errors.sku}
                                                                                touched={touched.sku}
                                                                                onBlur={handleBlur}
                                                                                setFieldValue={setFieldValue}
                                                                                options={
                                                                                    props.SKUs && !!props.SKUs.length
                                                                                        ? props.SKUs
                                                                                        : []
                                                                                }
                                                                                defaultValue={null}
                                                                                onChange={(e, value: any) => {
                                                                                    setFieldValue(
                                                                                        'sku',
                                                                                        value ? value.id : ''
                                                                                    );
                                                                                    props.setLocationFilter({
                                                                                        ...props.extraFiltering,
                                                                                        skuId: value ? value.id : ''
                                                                                    });
                                                                                }}
                                                                                optionsPropertyLabel='name'
                                                                                optionsPropertyValue='id'
                                                                                lg={3}
                                                                                md={3}
                                                                                sm={12}
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}

                                                                <OutlinedInputElement
                                                                    size='small'
                                                                    label={Trans('messages.t.serial_number')}
                                                                    value={props.serialNumber}
                                                                    onChange={(e) => {
                                                                        props.setSerialNumber(e.target.value);
                                                                        props.setLocationFilter({
                                                                            ...props.extraFiltering,
                                                                            serialNumber: e.target.value
                                                                        });
                                                                    }}
                                                                    name='serialNumber'
                                                                    endAdornment={
                                                                        <InputAdornment
                                                                            title={Trans('messages.t.reset')}
                                                                            position='end'
                                                                        >
                                                                            <IconButton
                                                                                onClick={() => {
                                                                                    props.setSerialNumber('');
                                                                                    props.setLocationFilter({
                                                                                        ...props.extraFiltering,
                                                                                        serialNumber: ''
                                                                                    });
                                                                                }}
                                                                                edge='end'
                                                                            >
                                                                                {props.serialNumber ? <Close /> : ''}
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    }
                                                                    show={true}
                                                                    lg={3}
                                                                    md={3}
                                                                />
                                                            </Grid>
                                                            <div
                                                                className='bodyRightItems'
                                                                style={
                                                                    !props.fullScreenModal ? { display: 'flex' } : {}
                                                                }
                                                            >
                                                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                                                    <div className='conttable'>
                                                                        <UiTable
                                                                            data={props.itemsFrom}
                                                                            loading={props.isLoading}
                                                                            columns={props.columnsAvailableItems}
                                                                            customButtonAdd={
                                                                                <b>
                                                                                    <span className='stc_fonsize'>
                                                                                        {Trans(
                                                                                            'messages.t.available_items'
                                                                                        )}
                                                                                    </span>
                                                                                </b>
                                                                            }
                                                                            total={props.total}
                                                                            extraFiltering={props.extraFiltering}
                                                                            pageCount={props.pageCount}
                                                                            fetchData={({
                                                                                page,
                                                                                limit,
                                                                                order,
                                                                                filter,
                                                                                extraFiltering
                                                                            }) => {
                                                                                props.fetchData(
                                                                                    page + 1,
                                                                                    limit,
                                                                                    order,
                                                                                    filter,
                                                                                    extraFiltering
                                                                                );
                                                                            }}
                                                                            fetchDataExport={props.fetchDataExport}
                                                                            hiddenColumns={[]}
                                                                            subtractSpace={heightScreen}
                                                                            forceRefresh={props.refresh}
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                                <Grid item lg={9} md={9} sm={12} xs={12}>
                                                                    <div className='conttable'>
                                                                        <UiTable
                                                                            classesTable='containerDataTable'
                                                                            data={props.itemsToSend}
                                                                            loading={props.isLoading}
                                                                            hidePagination={true}
                                                                            subtractSpace={heightScreen}
                                                                            hideForceRefresh={true}
                                                                            noPagination={true}
                                                                            columns={props.columnsItemsToSend}
                                                                            hiddenColumns={[]}
                                                                            globalFilter={true}
                                                                            customButtonAdd={
                                                                                <b>
                                                                                    <span className='stc_fonsize'>
                                                                                        {Trans(
                                                                                            'messages.t.item_to_send'
                                                                                        )}
                                                                                    </span>
                                                                                </b>
                                                                            }
                                                                        />
                                                                    </div>
                                                                </Grid>
                                                            </div>
                                                        </style.FieldsetContainer>
                                                    </Grid>
                                                </>
                                            </Grid>
                                        </DialogContent>
                                    </style.UiScrollbarsEditOrder>
                                    <DialogActions>
                                        <Button
                                            variant='contained'
                                            onClick={props.closeModal}
                                            color='secondary'
                                            size='small'
                                        >
                                            {Trans('messages.t.cancel')}
                                        </Button>
                                        &nbsp; &nbsp;
                                        <Button
                                            variant='contained'
                                            color='primary'
                                            type='button'
                                            disabled={isSubmitting}
                                            size='small'
                                            onClick={() => {
                                                submitForm();
                                            }}
                                        >
                                            {isSubmitting ? <CircularProgress size={25} /> : Trans('messages.t.save')}
                                        </Button>
                                    </DialogActions>
                                </Form>
                            );
                        }}
                    </Formik>
                )}
            </Dialog>
        </style.EditShippingOrderContent>
    );
};
