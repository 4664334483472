import React from 'react';
import * as style from './Reports.style';
import Reports from '../../components/Reports';
// import module

export const ReportsContent: React.FC<{}> = (props): JSX.Element => (
    <style.ReportsContent data-testid='ReportsContent'>
        <Reports />
    </style.ReportsContent>
);
