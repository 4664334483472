import React from 'react';
import { useRecoilValue } from 'recoil';
import Translator from '../../states/global/Translator';
import { SelectAsyncPaginate } from '../FormElements';
import { CustomerDropDownViewProps } from './CustomerDropDown.type';
// import module

export const CustomerDropDownContent: React.FC<CustomerDropDownViewProps> = ({
    value,
    error,
    customerList,
    onChange,
    required,
    name,
    label,
    loadMoreCustomer,
    lg,
    md,
    sm
}): JSX.Element => {
    const Trans = useRecoilValue(Translator);

    return (
        <SelectAsyncPaginate
            name={name}
            label={label}
            value={value}
            error={error}
            options={customerList}
            onChange={onChange}
            required={required}
            loadOptions={loadMoreCustomer}
            textRequired={Trans('messages.p.this_field_is_required')}
            lg={lg}
            md={md}
            sm={sm}
        />
    );
};
