import React, { useCallback, useEffect, useState } from 'react';
import { FormControlLabel, MenuItem, Switch, TextField } from '@mui/material';
import { CellProps } from 'react-table';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { FullItemsreports, ItemsProps } from './ItemsReport.type';
import { ItemsReportContent } from './ItemsReport.view';
import { useStyles } from './ItemsReport.style';
import ApiItems from '../../api/ApiItems';
import { ItemsModel } from '../../models/ApiItems.type';
import { Items } from '../../states/component/Items';
import Translator from '../../states/global/Translator';
import { ColumnsTable, ModelResponseGetGeneral } from '../../types/common';
import { FromTimezoneToUTC, FromUTCToTimezone, GetDateMask } from '../../helpers/Converters';
import { TextEllipsis } from '../../styled/global.style';
import DatePickerFilterColumn from '../Ui/UiTable/Components/DatePickerFilterColumn/DatePickerFilterColumn';
// import module

const apiItems = new ApiItems();

const ItemsReport: React.FC<ItemsProps> = () => {
    const Trans = useRecoilValue(Translator);
    const getDateMask = useRecoilValue(GetDateMask);
    const fromTimezoneToUTC = useRecoilValue(FromTimezoneToUTC);
    const classes = useStyles();
    const setdata = useSetRecoilState<ItemsModel[] | unknown>(Items);
    const fromUTCToTimezone = useRecoilValue(FromUTCToTimezone);
    const [loading, setLoading] = useState<boolean>(true);
    const [pageCount, setPageCount] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [itemsReportFilter, setItemsReportFilter] = useState<
        { locationFilter: string; availableFilter: string } | Record<string, unknown>
    >({});
    const [available, setAvailable] = useState<boolean>(true);

    async function getData(page, limit, order, filter, extraFiltering) {
        let filterItem = '';
        filterItem = `${filterItem}?${extraFiltering.availableFilter}`;

        return await apiItems.getAll(filterItem, page, limit, order, filter, '/v2');
    }

    const getAllItems = useCallback(
        async (page, limit, order, filter, extraFiltering): Promise<void> => {
            setLoading(true);
            setdata([]);

            const { items, paginator }: ModelResponseGetGeneral = await getData(
                page,
                limit,
                order,
                filter,
                extraFiltering
            );

            setdata(items);
            setPageCount(paginator.pageCount);
            setTotal(paginator.totalCount);
            setLoading(false);
        },
        [setdata]
    );

    const getForExport = async (order, filter, extraFiltering) => {
        let pages = 1;
        let itemsData: any[] = [];

        for (let i = 1; i <= pages; i++) {
            const { items, paginator } = await getData(i, 200, order, filter, extraFiltering);

            pages = paginator.pageCount;
            itemsData = itemsData.concat(items);
        }

        return itemsData;
    };

    const handleChange = (event): void => {
        event.target.checked
            ? setItemsReportFilter({ ...itemsReportFilter, availableFilter: 'available=1' })
            : setItemsReportFilter({ ...itemsReportFilter, availableFilter: '' });
        setAvailable(event.target.checked);
    };

    const ElementFilter = (
        <FormControlLabel
            control={<Switch checked={available} onChange={handleChange} name='available' color='primary' />}
            label={Trans('messages.t.available')}
        />
    );

    const columnsItems: ColumnsTable[] = [
        {
            Header: 'ID',
            accessor: 'id',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 50
        },
        {
            Header: Trans('messages.t.type'),
            accessor: 'type',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 60,
            Export: ({ row }: CellProps) => Trans('messages.t.' + row.original.sku.skuType.type),
            Cell: ({ row }: CellProps<FullItemsreports>) => {
                return (
                    <div style={{ textTransform: 'capitalize' }}>
                        {Trans('messages.t.' + row.original.sku.skuType.type)}
                    </div>
                );
            },
            Filter: ({ column, setFilter }: CellProps<FullItemsreports>) => {
                return (
                    <TextField
                        className={classes.inputFilterColumn}
                        select
                        variant='outlined'
                        label={column.Header}
                        value={column.filterValue?.value || null}
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={(e) => {
                            const value = !e.target.value
                                ? undefined
                                : {
                                      name: column.Header,
                                      value: e.target.value
                                  };
                            setFilter(column.id, value);
                        }}
                    >
                        <MenuItem value='box'>{Trans('messages.t.box')}</MenuItem>
                        <MenuItem value='generic_item'>{Trans('messages.t.generic_item')}</MenuItem>
                        <MenuItem value='hub'>{Trans('messages.t.hub')}</MenuItem>
                        <MenuItem value='sensor'>{Trans('messages.t.sensor')}</MenuItem>
                        <MenuItem value='tablet'>{Trans('messages.t.tablet')}</MenuItem>
                    </TextField>
                );
            }
        },
        {
            Header: Trans('messages.t.serial_number'),
            accessor: 'serialNumber',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: true,
            width: 80,
            Cell: ({ row }: CellProps<FullItemsreports>) =>
                row.original.serialNumber ? row.original.serialNumber : '- - - -'
        },
        {
            Header: Trans('messages.t.name'),
            accessor: 'sku.name',
            disableGroupBy: true,
            aggregate: 'count',
            disableFilters: true,
            Cell: ({ row }: CellProps<FullItemsreports>) => (
                <TextEllipsis title={row.original.sku.name}> {row.original.sku.name} </TextEllipsis>
            )
        },
        {
            Header: Trans('messages.t.stock_location'),
            accessor: 'locationName',
            disableGroupBy: true,
            aggregate: 'count',
            canFilters: false,
            Export: ({ row }: CellProps) => (row.original.location ? row.original.location.name : ''),
            Cell: ({ row }: CellProps<FullItemsreports>) => (
                <TextEllipsis title={row.original.location.name}>
                    {row.original.location ? row.original.location.name : '- - - -'}
                </TextEllipsis>
            )
        },
        {
            Header: Trans('messages.t.quantity'),
            accessor: 'quantity',
            disableGroupBy: true,
            disableFilters: true,
            aggregate: 'count',
            width: 70
        },
        {
            Header: Trans('messages.t.shipped_by'),
            accessor: 'shippedBy',
            disableGroupBy: true,
            aggregate: 'count',
            Export: ({ row }: CellProps) =>
                row.original.shippedBy ? row.original.shippedBy.firstname + ' ' + row.original.shippedBy.lastname : '',
            Cell: ({ row }: CellProps<FullItemsreports>) =>
                row.original.shippedBy
                    ? row.original.shippedBy.firstname + ' ' + row.original.shippedBy.lastname
                    : '---'
        },
        {
            Header: Trans('messages.t.shipped_at'),
            accessor: 'shippedAt',
            disableGroupBy: true,
            aggregate: 'count',
            Cell: ({ row }: CellProps) => fromUTCToTimezone(row.original?.shippedAt || '', false),
            canFilters: true,
            Filter: ({ setFilter, state }: CellProps) => {
                return (
                    <>
                        <DatePickerFilterColumn
                            state={state}
                            setFilter={setFilter}
                            label={Trans('messages.t.shipped_at_from')}
                            filterName='shippedAtFrom'
                            getDateMask={getDateMask}
                            fromTimezoneToUTC={fromTimezoneToUTC}
                        />
                        <DatePickerFilterColumn
                            state={state}
                            setFilter={setFilter}
                            label={Trans('messages.t.shipped_at_to')}
                            filterName='shippedAtTo'
                            getDateMask={getDateMask}
                            fromTimezoneToUTC={fromTimezoneToUTC}
                        />
                    </>
                );
            }
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            disableGroupBy: true,
            aggregate: 'count',
            width: 0,
            accessor: 'shippedAtFrom'
        },
        {
            Header: '',
            disableFilters: true,
            disableSortBy: true,
            width: 0,
            disableGroupBy: true,
            aggregate: 'count',
            accessor: 'shippedAtTo'
        }
    ];

    useEffect(() => {
        setItemsReportFilter({ availableFilter: 'available=1' });
    }, [getAllItems]);

    return (
        <ItemsReportContent
            data-testid={'ItemsReports-testid'}
            columns={columnsItems}
            isLoading={loading}
            fetchData={getAllItems}
            fetchDataExport={getForExport}
            total={total}
            pageCount={pageCount}
            extraFiltering={itemsReportFilter}
            elementFilter={ElementFilter}
        />
    );
};

export default ItemsReport;
