import React, { useState, useEffect } from 'react';
import * as style from './Reports.style';
import * as view from './Reports.view';
import * as type from './Reports.type';
import Wrapper from '../../helpers/wrapper';
// import module

const Reports: React.FC<type.ReportsProps> = ({}): JSX.Element => {
    return <view.ReportsContent data-testid={'Reports-testid'} />;
};

export default Wrapper(Reports);
