import PortalApi from '../helpers/api/portalApi';
import { formatParametersToGetPagination } from '../helpers/converter';
import { Model } from '../models/ApiShippingOrders.type';

export default class ApiShippingOrders extends PortalApi<Model> {
    route = 'stock/shipping-order';

    getAll = async (route, page, limit, order, filter): Promise<any> => {
        return await this.getGeneralTable<any>(
            route,
            '/v1',
            formatParametersToGetPagination(page, limit, order, filter)
        );
    };

    getXlsx = async (route): Promise<{ response: Blob; fileName: string }> => {
        const params: { [key: string]: string | number } = {};
        params.responseType = 'blob';
        return await this.getDowloadFile(`/${route}`, params);
    };
}
